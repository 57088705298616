import React, { useMemo } from 'react'
import { Link, useLocation, useNavigation } from 'react-router-dom'
import { APP_NAV_TABS, convertToCamelCaseNormal, NAV_TABS, USER_ROLES } from 'src/helpers'
import { useAuth } from 'src/modules/Auth/Core'
import { MAIN_ROUTES_CONST } from 'src/navigations/modules'

import NavMenuComponent, { INavMenu } from 'src/shadcn/components/custom/NavMenuComponent'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'src/shadcn/components/ui/tabs'

type Props = {}


const DEFAULT_NAV: INavMenu = [
    {
        title: "Home",
        link: MAIN_ROUTES_CONST.welcome,
    },
    {
        title: "Dashboard",
        link: MAIN_ROUTES_CONST.dashboard,
    },
    {
        title: "Marketplace",
        link: MAIN_ROUTES_CONST.marketplace,
    },
    {
        title: "Services",
        subLinks: [
            {
                title: "EPC Contractors",
                link: MAIN_ROUTES_CONST.epcContractor
            },
            {
                title: "Manufacturer",
                link: MAIN_ROUTES_CONST.manufacturers
            }
        ]
    },
    {
        title: "Sell",
        link: MAIN_ROUTES_CONST.sell,
    },
    {
        title: "Contact Us",
        link: MAIN_ROUTES_CONST.contact_us,
    },
    {
        title: "Investment Calculator",
        link: MAIN_ROUTES_CONST.investment_calculator,
    },
    {
        title: "Enquiry Request",
        link: MAIN_ROUTES_CONST.new_enquiry_request,
    },
]


const NavigationMenu = (props: Props) => {

    const { pathname } = useLocation()
    const { currentUser } = useAuth()

    const NAVIGATOIN_MENU_ITEMS = useMemo(() => {
        let routes: any = DEFAULT_NAV
        if (currentUser?.role == USER_ROLES.INVESTOR) {
            return DEFAULT_NAV.filter(({ title }: any) => !["Home", "Enquiry Request"].includes(title))
        }
        else if (currentUser?.role == USER_ROLES.OFF_TAKER) {
            return DEFAULT_NAV.filter(({ title }: any) => !["Home", "Investment Calculator"].includes(title))
        }
        return DEFAULT_NAV.filter(({ title }: any) => !["Dashboard", "Investment Calculator","Enquiry Request"].includes(title))
    }, [currentUser, DEFAULT_NAV])

    return (
        <div>
            <NavMenuComponent navs={NAVIGATOIN_MENU_ITEMS} />
        </div>
    )
}

export default NavigationMenu