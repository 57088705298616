import { MAIN_ROUTES_CONST } from "src/navigations/modules";
import { toAbsoluteUrl } from "../AssetHelpers";
import MainRoutes from "src/navigations";
import { ClassNameValue } from "tailwind-merge";


export const ERROR_CODES = [400, 500, 404];

export type optionTypes = {
  label: string,
  value: string,
  icons?:any
}

export type componentType = 'input' | 'inputicon' | 'options' | 'date_picker' | 'file_picker' | "textarea";

export interface FormField {
  label: string;
  dataKey: string;
  value?: string,
  componentType: componentType,
  componentProps?: {
    type?: 'email' | 'password' | 'phone' | 'text' | 'url',
    className?: string,
    placeholder?: string
    regex?: RegExp,
    maxLength?: number,
    uppercase?: boolean,
    options?: optionTypes[],
    inputSuffixIcon?: any,
    inputPrefixIcon?: any,
    required?: boolean,
    readonly?: boolean,
    disabled?: boolean,
    showCharacterLength?: boolean,
    fileSelectionType?: "single" | "multi",
    numberFormatted?: boolean,
    labelStyle?: ClassNameValue,
    description?: string
    conditionalDisabled?: {
      formKey: string,
      expectedValue: string
    },
    inputWidth?: number,
    hide?: {
      formKey: string,
      expectedValue: string
    },
    conditionalHide?: {
      formKey: string,
      expectedValue: string
    } & Record<string, any>;
  }
}


export const NAV_TABS = {
  home: MAIN_ROUTES_CONST.welcome,
  marketplace: MAIN_ROUTES_CONST.marketplace,
  sell: MAIN_ROUTES_CONST.sell,
  contact_us: MAIN_ROUTES_CONST.contact_us
}

export const APP_NAV_TABS = {
  dashboard: MAIN_ROUTES_CONST.dashboard,
  marketplace: MAIN_ROUTES_CONST.marketplace,
  investment_calculator: MAIN_ROUTES_CONST.investment_calculator,
  sell: MAIN_ROUTES_CONST.sell,
  enquiry_request: MAIN_ROUTES_CONST.new_enquiry_request,
  contact_us: MAIN_ROUTES_CONST.contact_us,
}

export const HERO_IMG_1 = toAbsoluteUrl("/media/png/hero-img-1.png")
export const IMG_FEATURE_P2P_ENERGY_TRADING = toAbsoluteUrl("/media/png/featutre_p2p_energy_trading.png")
export const IMG_FEATURE_P2P_CROWDFUNDING = toAbsoluteUrl("/media/png/featutre_p2p_crowed_funding.png")
export const IMG_FEATURE_P2P_MARKETPLACE = toAbsoluteUrl("/media/png/featutre_p2p_market_place.png")
export const IMG_DISCOVERS_BG = toAbsoluteUrl("/media/png/discover_bg.png")
export const IMG_FEATURE_INFO_1 = toAbsoluteUrl("/media/png/info1.png")
export const SVG_FEATURE_INFO_1 = toAbsoluteUrl("/media/svg/image/info1.svg")
export const IMG_FEATURE_INFO_2 = toAbsoluteUrl("/media/png/info2.png")
export const IMG_FEATURE_INFO_3 = toAbsoluteUrl("/media/png/info3.png")
export const IMG_FEATURE_INFO_4 = toAbsoluteUrl("/media/png/info4.png")
export const IMG_FEATURE_INFO_5 = toAbsoluteUrl("/media/png/info5.png")
export const ICON_ENERGY_SUN = toAbsoluteUrl("/media/svg/icons/energy_sun.svg")
export const ICON_GREEN_ENERGY = toAbsoluteUrl("/media/svg/icons/green_energy.svg")
export const ICON_BASKET = toAbsoluteUrl("/media/svg/icons/basket.svg")
export const ICON_PAYMENT_RECEIVE = toAbsoluteUrl("/media/svg/icons/payment_receive.svg")
export const ICON_EMAIL = toAbsoluteUrl("/media/svg/icons/email.svg")
export const IMG_FAQ_BANNER = toAbsoluteUrl("/media/jpg/faq_banner.png")
export const IMG_ABOUT_US_BANNER = toAbsoluteUrl("/media/jpg/about_us_banner.jpg")
export const IMG_CONTACT_US_BANNER = toAbsoluteUrl("/media/jpg/contact_us_banner.jpg")
export const IMG_CROWDFUNDING_BANNER = toAbsoluteUrl("/media/jpg/croudfunding_banner.png")
export const IMG_GREEN_ENERGY_BANNER = toAbsoluteUrl("/media/jpg/green_energy_banner.png")
export const IMG_CROWDFUNDING_SELL_ENERGY = toAbsoluteUrl("/media/png/sellEnergy.png")
export const IMG_CROWDFUNDING_CROWD_FUNDING = toAbsoluteUrl("/media/png/crowd-funding.png")
export const IMG_CROWDFUNDING_BUY_ENERGY = toAbsoluteUrl("/media/png/BuyersFundig.png")
export const IMG_GREEN_ENERGY_AGGREMENT = toAbsoluteUrl("/media/png/Agreement.png")
export const IMG_P2P_ENERGY_TRADING = toAbsoluteUrl("/media/jpg/p2p_energy_trading.jpg")
export const IMG_GREEN_ENERGY_TOKENIZED = toAbsoluteUrl("/media/png/TokenizedSolar.png")
export const IMG_GREEN_ENERGY_VIRTUAL_ROOFTOP = toAbsoluteUrl("/media/png/Virtual_Rooftop.png")
export const ICON_CURLY = toAbsoluteUrl("/media/png/curly.png")
export const ICON_PROJECT_SIZE = toAbsoluteUrl("/media/png/project_size.png")
export const ICON_PROJECT_TYPE = toAbsoluteUrl("/media/png/project_type.png")
export const ICON_ROOF_TOP = toAbsoluteUrl("/media/png/roof-top.png")
export const ICON_SEND = toAbsoluteUrl("/media/png/send.png")
export const ICON_RECEIVE = toAbsoluteUrl("/media/png/receive.png")
export const ICON_PROFIT_AS_BUYER = toAbsoluteUrl("/media/png/profitAsBuyerIcon.png")
export const ICON_PROFIT_AS_SALER = toAbsoluteUrl("/media/png/profitAsSallerIcon.png")
export const ICON_WALLET = toAbsoluteUrl("/media/png/Wallet.png")
export const ICON_OCT_VERIFIED = toAbsoluteUrl("/media/png/oct-verified.png")
export const IMG_EPC_COMPANY_PLACEHOLDER_LOGO = toAbsoluteUrl("/media/png/company-logo.png")
export const IMG_EPC_COMPANY_LOGO = toAbsoluteUrl("/media/png/Nous.png")
export const IMG_SOLAR_ROOF_TOP = toAbsoluteUrl("/media/png/solar-rooftops.png")
export const IMG_SOLAR_FARMS = toAbsoluteUrl("/media/png/solar-farms.png")
export const IMG_SOLAR_WATER_PUMP = toAbsoluteUrl("/media/png/Solar-Water-Pumps.png")
export const IMG_SAMPLE_PANEL_1 = toAbsoluteUrl("/media/png/sample_panel_1.png")
export const IMG_SAMPLE_PANEL_2 = toAbsoluteUrl("/media/png/sample_panel_2.png")

export const PROJECT_IMAGE_URL = "https://images.unsplash.com/photo-1497440001374-f26997328c1b?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"

export const successTheme = {
  background: '#fff',
  color: "hsl(var(--primary))",
  borderRadius: '8px',
  padding: '16px',
};

export const errorTheme = {
  background: '#FF5722',
  color: '#FFF',
  borderRadius: '8px',
  padding: '16px',
};


export const USER_ROLES = {
  "INVESTOR": "investor",
  "OFF_TAKER": "offtakers"
}


export const NUMBER_REGEX = /[^0-9.]+/g
export const PHONE_NUMBER_REGEX = /[^0-9]+/g
export const EMAIL_REGEX = /[^a-z0-9.@]+/g
export const NAME_REGEX = /[^A-z ]+/g

export const SESSIONS = {
  FORGOT_PASSWORD: "FORGOT-PASSWORD",
  REGISTRATION: "REGISTRATION"
}


export const PROJECT_CATEGORY_OPTIONS = [
  {
    "label": "Community Solar",
    "value": "Community Solar"
  },
  {
    "label": "Ground-Mounted",
    "value": "Ground-Mounted"
  },
  {
    "label": "Floating Solar",
    "value": "Floating Solar"
  },
  {
    "label": "Solar Parks",
    "value": "Solar Parks"
  },
  {
    "label": "Rooftop Solar",
    "value": "Rooftop Solar"
  },
  {
    "label": "Carport Solar",
    "value": "Carport Solar"
  },
  {
    "label": "Hybrid (Solar + Wind +Battery)",
    "value": "Hybrid (Solar + Wind +Battery)"
  },
  {
    "label": "BESS (Energy Storage System)",
    "value": "BESS (Energy Storage System)"
  }
]

export const PROJECT_TYPES = [
  // { label: 'Matchmaking', value: 'Matchmaking' },
  { label: 'BOOT', value: 'BOOT' },
  // { label: 'EaaS', value: 'EaaS' },
  // { label: 'VPP', value: 'VPP' },
];

export const PROPERTY_TYPE_OPTIONS = [{
  "label": "Rented",
  "value": "Rented"
}, {
  "label": "Ownership",
  "value": "Ownership"
}];

export const BUILDING_TYPES = [{
  "label": "Residential",
  "value": "Residential"
}, {
  "label": "Commercial",
  "value": "Commercial"
}, {
  "label": "Industrial",
  "value": "Industrial"
}];



export const AUTHORIZED_PARTNER_OPTIONS = [{
  "label": "Gold",
  "value": "Gold"
}, {
  "label": "Platinum",
  "value": "Platinum"
}, {
  "label": "Silver",
  "value": "Silver"
}];



export const VERIFIED_UNVERIFIED_OPTIONS = [{
  "label": "Verified",
  "value": "Verified"
}, {
  "label": "Unverified",
  "value": "Unverified"
}];

// export const BUILDING_TYPES = [
//   { label: 'Residential Building', value: 'residential_building' },
//   { label: 'Commercial Building', value: 'commercial_building' },
//   { label: 'Industrial Building', value: 'industrial_building' },
// ];

export const SYSTEM_TYPES = [
  {
    "label": "Photovoltaic(PV)",
    "value": "Photovoltaic"
  },
  {
    "label": "Solar Thermal",
    "value": "Solar Thermal"
  },
  {
    "label": "Off-Grid Solar",
    "value": "Off-Grid Solar"
  },
  {
    "label": "On-Grid Solar",
    "value": "On-Grid Solar"
  },
  {
    "label": "Hybrid Systems",
    "value": "Hybrid Systems"
  },
  {
    "label": "Concentrated Solar Power (CSP)",
    "value": "Concentrated Solar Power (CSP)"
  },
  {
    "label": "Building-Integrated Photovoltaic (BIPV)",
    "value": "Building-Integrated Photovoltaic (BIPV)"
  },
  {
    "label": "Floating Solar",
    "value": "Floating Solar"
  },
  {
    "label": "Agrivoltaics (Agro-Solar)",
    "value": "Agrivoltaics (Agro-Solar)"
  }
]

export const PROJECT_LAND_TYPES = [
  { label: 'Residential', value: 'Residential' },
  { label: 'Commercial', value: 'Commercial' },
  { label: 'Industrial', value: 'Industrial' },
  { label: 'Agricultural', value: 'Agricultural' },
  { label: 'Institutional', value: 'Institutional' },
  { label: 'Government', value: 'Government' },
];

export const DUMMY_OPTIONS = [
  {
    label: 'Type 1',
    value: 'type1',
  },
  {
    label: 'Type 2',
    value: 'type2',
  },
]


export const indianNumberFormatter = new Intl.NumberFormat('en-IN', {
  maximumFractionDigits: 2,
  style: 'currency',
  currency: 'INR'
});

export const AVAILABLE_SPACE_UNITS_OPTIONS = [
  { label: "SqFt", value: "SqFt" },
  { label: "meter", value: "m" },
  { label: "feet", value: "ft" },
  { label: "SqM", value: "SqM" },
]

export const PROPERTY_TYPE_ICONS = {
  "BOOT": toAbsoluteUrl("/media/png/boot.png"),
}

export const BUILDING_TYPE_ICONS = {
  "Residential": toAbsoluteUrl("/media/png/property-residential.png"),
  "Commercial": toAbsoluteUrl("/media/png/property-commercial.png"),
  "Industrial": toAbsoluteUrl("/media/png/property-industry.png")
}

export const QUERY_TYPE_OPTIONS = [
  { label: "Account", value: "Account" },
  { label: "Project Information", value: "Project Information" },
  { label: "General Enquiry", value: "General Enquiry" },
  { label: "Transaction", value: "Transaction" },
  { label: "Investor", value: "Investor" },
  { label: "Offtaker", value: "Offtaker" }
]


export const EPC_CONTRACTORS_SPECIALIZATIONS = [
  "Solar farm design and engineering",
  "Procurement of solar panels, inverters, and other equipment",
  "Construction and installation of solar farms",
  "Routine maintenance and repair of solar farms",
  "Performance monitoring and optimization",
  "Troubleshooting and fault resolution",
  "Electrical infrastructure design and installation",
  "Electrical testing and commissioning",
  "Electrical maintenance and repair",
  "Site preparation and grading",
  "Foundation design and installation",
  "Road construction and maintenance",
  "Environmental impact assessments",
  "Permitting and compliance",
  "Wildlife habitat assessments and mitigation"
]

export const MANUFACTURERS_SPECIALIZATIONS = [
  "Monocrystalline silicon solar panels",
  "Polycrystalline silicon solar panels",
  "Thin-film solar panels",
  "Bifacial solar panels",
  "String inverters",
  "Central inverters",
  "Microinverters",
  "Power optimizers",
  "Fixed-tilt mounting systems",
  "Tracking mounting systems",
  "Roof-top mounting systems",
  "Ground-mounted systems",
  "Lithium-ion battery systems",
  "Lead-acid battery systems",
  "Flow battery systems",
  "Sodium-ion battery systems",
  "Data loggers",
  "Monitoring software",
  "Control systems for inverters and trackers",
  "Weather stations"
]

export const OTHER_SPECIALIZATIONS = [
  "Residential solar installations",
  "Commercial solar installations",
  "Utility-scale solar installations",
  "String inverter installation",
  "Central inverter installation",
  "Microinverter installation",
  "Electrical testing and inspection",
  "Commissioning of electrical systems",
  "Electrical safety auditing",
  "Preventive maintenance",
  "Corrective maintenance",
  "Predictive maintenance",
  "Energy audits and assessments",
  "Energy efficiency recommendations",
  "Energy management and monitoring"
]


export const SPECIALIZATION_OPTIONS = [...EPC_CONTRACTORS_SPECIALIZATIONS, ...MANUFACTURERS_SPECIALIZATIONS, ...OTHER_SPECIALIZATIONS].map((d: any) => ({ label: d, value: d }))
